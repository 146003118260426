/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import PropTypes from 'prop-types'
import React from 'react'
import Footer from './footer'
import './layout.scss'
import Navigation from './navigation'
import Helmet from 'react-helmet'

const Layout = ({ children }) => (
  <>
    <Helmet
      bodyAttributes={{
        class: 'has-navbar-fixed-top',
      }}
    />

    <div className="container padding-side">
      <header>
        <Navigation />
      </header>

      <br />

      <main>{children}</main>

      <br />

      <Footer />
    </div>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
