import { Link } from 'gatsby'
import React from 'react'

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
    }
  }

  toggleMenu = () => this.setState({ menuOpen: !this.state.menuOpen })

  render() {
    return (
      <nav
        className="navbar is-fixed-top container"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <span
            role="button"
            className={`navbar-burger ${
              this.state.menuOpen ? 'is-active' : ''
            }`}
            aria-label="menu"
            aria-expanded="false"
            onClick={this.toggleMenu}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </span>
        </div>
        <div
          className={`navbar-menu ${this.state.menuOpen ? 'is-active' : ''}`}
        >
          <div className="navbar-start">
            <Link to="/" className="navbar-item">
              Domov
            </Link>
            <Link to="/galeria" className="navbar-item">
              Galéria
            </Link>
            <Link to="/vzorkovnik" className="navbar-item">
              Vzorkovník
            </Link>
            <Link to="/referencie" className="navbar-item">
              Referencie
            </Link>
            <Link to="/kontakt" className="navbar-item">
              Kontakt
            </Link>
          </div>
          <div className="navbar-end" />
        </div>
      </nav>
    )
  }
}

export default Navigation
