import React from 'react'

export default () => {
  return (
    <footer className="footer">
      <div className="columns">
        <div className="column">
          <h3 className="title">Majo Interiér</h3>
          <a href="tel:0907642554">0907 642 554</a>
          <br />
          <a href="mailto:jajcay@majointerier.sk">jajcay@majointerier.sk</a>
        </div>
      </div>
    </footer>
  )
}
