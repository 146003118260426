import React from 'react'
import Layout from '../components/layout'
import axios from 'axios'
import SEO from '../components/seo'

class KontaktPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      success: false,
      error: false,
      userConsent: false,
    }
  }

  textareaStyle = {
    height: '10em',
  }

  onFormSubmit = event => {
    event.preventDefault()

    this.setState({ isLoading: true })

    const { name, email, text, tel } = event.target.elements

    const dataToSend = {
      name: name.value || `Neuvedené`,
      email: email.value || `Neuvedené`,
      tel: tel.value || `Neuvedené`,
      text: text.value || `Neuvedené`,
    }

    axios
      .post('/.netlify/functions/mail', dataToSend)
      .then(res => {
        this.setState({ isLoading: false, success: true })
      })
      .catch(err => {
        this.setState({ isLoading: false, error: true })
      })
  }

  checkboxChange = event => {
    this.setState({ userConsent: event.target.checked })
  }

  render() {
    return (
      <Layout>
        <SEO title="Kontakt" description="Kontakt na nás" />

        <div className="columns">
          <div className="column is-6 is-offset-3">
            <h1 className="title">Kontakt</h1>
            <h2 className="subtitle">
              Kontaktujte nás pre <b>bezplatnú a nezáväznú</b> cenovú ponuku
            </h2>

            <address>
              <b>Jozef Jajcay</b> <br />{' '}
              <a href="tel:+421907642554">0907 642 554</a> <br />{' '}
              <a href="mailto:jajcay@majointerier.sk">jajcay@majointerier.sk</a>
            </address>

            <hr />

            <h2 className="title">Kontaktný formulár</h2>
            <p>
              Ide to aj naopak - ak odošlete kontaktný formulár, kontaktujeme my
              Vás.
            </p>
            <br />
            <form onSubmit={this.onFormSubmit}>
              <div className="field">
                <label className="label">Meno a priezvisko</label>
                <div className="control">
                  <input className="input" type="text" name="name" />
                </div>
              </div>
              <div className="field">
                <label className="label">Email</label>
                <div className="control">
                  <input className="input" type="text" name="email" />
                </div>
              </div>
              <div className="field">
                <label className="label">Tel. číslo</label>
                <div className="control">
                  <input className="input" type="tel" name="tel" />
                </div>
              </div>
              <div className="field">
                <label className="label">Text</label>
                <div className="control">
                  <textarea
                    // value="Dobrý deň, mám záujem o cenovú ponuku. Kontaktujte ma prosím."
                    name="text"
                    style={this.textareaStyle}
                    className="input"
                    placeholder="Otázky alebo pripomienky týkajúce sa dopytu..."
                  />
                </div>
              </div>

              <div className="control">
                <label className="checkbox">
                  <input onChange={this.checkboxChange} type="checkbox" />{' '}
                  Súhlasím so spracovaním osobných údajov výhradne za účelom
                  spracovania dopytu, vypracovania návrhu a cenovej ponuky.
                </label>
              </div>

              <br />

              <div className="control">
                <button
                  disabled={this.state.success || !this.state.userConsent}
                  type="submit"
                  className={
                    'button is-primary ' +
                    `${this.state.isLoading ? 'is-loading' : ''}`
                  }
                >
                  Odoslať
                </button>
              </div>
            </form>
          </div>
        </div>

        <div aria-hidden="true" className="columns">
          <div className="column is-6 is-offset-3">
            <article
              className="message is-success"
              style={{ display: this.state.success ? '' : 'none' }}
            >
              <div className="message-header">
                <p>Podarilo sa!</p>
                <button className="delete" aria-label="delete" />
              </div>
              <div className="message-body">
                Ďakujeme za záujem, prosím čakajte na kontakt z našej strany.
              </div>
            </article>

            <article
              className="message is-danger"
              style={{ display: this.state.error ? '' : 'none' }}
            >
              <div className="message-header">
                <p>Chyba!</p>
                <button
                  onClick={ev => this.setState({ error: false })}
                  className="delete"
                  aria-label="delete"
                />
              </div>
              <div className="message-body">
                Hups! Nastala chyba, skúste prosím neskôr.
              </div>
            </article>
          </div>
        </div>
      </Layout>
    )
  }
}

export default KontaktPage
